import axiosIns from '@/libs/axios'

export function getAddress({ id }) {
  return axiosIns.get(`/geolocation/addresses/${id}`, {
    params: {
    },
  })
}

export function getAddresses({
  perPage, sortedBy, orderBy, page, search, searchFields, searchJoin, includes,
}) {
  return axiosIns.get('/geolocation/addresses', {
    params: {
      perPage,
      sortedBy,
      orderBy,
      page,
      search,
      searchFields,
      searchJoin,
      includes,
    },
  })
}

export function createAddress({
  company_id,
  latitude,
  longitude,
  postcode,
  country,
  province,
  district,
  locality,
  street,
  house,
  building,
  apartment,
}) {
  return axiosIns.post('/geolocation/addresses', {
    company_id,
    latitude,
    longitude,
    postcode,
    country,
    province,
    district,
    locality,
    street,
    house,
    building,
    apartment,
  })
}

export function updateAddress({
  id,
  company_id,
  latitude,
  longitude,
  postcode,
  country,
  province,
  district,
  locality,
  street,
  house,
  building,
  apartment,
  model,
  model_id,
  model_field,
  model_sort_order,
}) {
  return axiosIns.patch(`/geolocation/addresses/${id}`, {
    company_id,
    latitude,
    longitude,
    postcode,
    country,
    province,
    district,
    locality,
    street,
    house,
    building,
    apartment,
    model,
    model_id,
    model_field,
    model_sort_order,
  })
}

export function deleteAddress(id) {
  return axiosIns.delete(`/geolocation/addresses/${id}`)
}

export default {
  getAddress,
  getAddresses,
  createAddress,
  updateAddress,
  deleteAddress,
}
