<template>
  <div class="a-address">
    <div class="d-flex mt-2 mb-1">
      <feather-icon
        icon="NavigationIcon"
        size="19"
      />
      <h4 class="mb-0 ml-50">
        {{ title }}
      </h4>
    </div>
    <b-row>
      <b-col
        v-for="fieldEntry in Object.entries($options.labelsAddressFields)"
        :key="'af' + fieldEntry[0]"
        cols="12"
        sm="6"
        md="4"
        lg="3"
      >
        <b-form-group
          :label="fieldEntry[1]"
          :label-for="'af' + fieldEntry[0]"
        >
          <b-form-input
            :id="'af' + fieldEntry[0]"
            v-model.trim="address[fieldEntry[0]]"
            :placeholder="mode === 'view' ? '-' : 'Введите значение'"
            :readonly="mode === 'view'"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
} from 'bootstrap-vue'

export default {
  name: 'AddressField',
  initialComplete: false,
  labelsAddressFields: {
    latitude: 'Широта',
    longitude: 'Долгота',
    postcode: 'Индекс',
    country: 'Страна',
    province: 'Регион',
    district: 'Район',
    locality: 'Населённый пункт',
    street: 'Улица',
    house: 'Дом',
    building: 'Строение',
    apartment: 'Квартира/Офис/Помещение',
  },
  components: {
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
  },
  props: {
    title: {
      type: String,
      default: 'Адрес',
    },
    value: {
      type: Object,
      default: () => ({
        id: null, // Идентификатор адреса
        company_id: null, // Идентификатор компании
        latitude: '', // Широта
        longitude: '', // Долгота
        postcode: '', // Индекс
        country: '', // Страна
        province: '', // Регион
        district: '', // Район
        locality: '', // Населённый пункт
        street: '', // Улица
        house: '', // Дом
        building: '', // Строение
        apartment: '', // Квартира/Офис/Помещение
        model: '', // Идентификатор записи модели, обязателен если в поле model указан один из допустимых параметров.
        model_id: null, // Идентификатор записи модели, обязателен если в поле model указан один из допустимых параметров.
        model_field: '', // Поле модели к которому привязывается файл, обязательно, если в поле model указан один из допустимых параметров. Допустимые значения для модели указаны в скобках: warehouse (real_address).
        model_sort_order: null, // Поле сортировки адресов внутри поля модели
      }),
    },
    mode: {
      type: String,
      default: '', // view
    },
  },
  data() {
    return {
      address: {
        id: null, // Идентификатор адреса
        company_id: null, // Идентификатор компании
        latitude: '', // Широта
        longitude: '', // Долгота
        postcode: '', // Индекс
        country: '', // Страна
        province: '', // Регион
        district: '', // Район
        locality: '', // Населённый пункт
        street: '', // Улица
        house: '', // Дом
        building: '', // Строение
        apartment: '', // Квартира/Офис/Помещение
        model: '', // Идентификатор записи модели, обязателен если в поле model указан один из допустимых параметров.
        model_id: null, // Идентификатор записи модели, обязателен если в поле model указан один из допустимых параметров.
        model_field: '', // Поле модели к которому привязывается файл, обязательно, если в поле model указан один из допустимых параметров. Допустимые значения для модели указаны в скобках: warehouse (real_address).
        model_sort_order: null, // Поле сортировки адресов внутри поля модели
      },
    }
  },
  watch: {
    address: {
      deep: true,
      handler(newValue) {
        this.$emit('input', newValue)
      },
    },
    value(newValue) {
      if (!this.$options.initialComplete) {
        const newAddress = { ...this.address }

        Object.keys(newValue).forEach(key => {
          // eslint-disable-next-line no-prototype-builtins
          if (newAddress.hasOwnProperty(key)) {
            newAddress[key] = newValue[key]
          }
        })

        this.address = newAddress

        this.$options.initialComplete = true
      }
    },
  },
}
</script>

<style scoped>

</style>
